import React from 'react';
import { Row, Col, Skeleton, Divider, Alert } from 'antd';

class Information extends React.Component {

  render() {
    const { loading, dataDiri, dataDashboard } = this.props
    const jenisJalur = <span className="bold">{dataDiri ? (dataDiri.ppdb_jalur_kategori ? (dataDiri.ppdb_jalur_kategori + " (" + dataDiri.ppdb_jalur_nama + ")") : "-") : "-"}</span>

    let hasilKelulusan;
    let typeAlert;
    if (!dataDashboard) {
      return <></>
    }
    let statusPPDB = dataDashboard.status_ppdb
    if (statusPPDB && statusPPDB.status === "Lolos") {
      typeAlert = "info";
      hasilKelulusan = <>Selamat anda dinyatakan <span className="bold">LOLOS</span> PPDB MAN 1 Gresik jalur {jenisJalur} Harap mengunduh dan menyetak surat pernyataan lolos PPDB untuk dibawa ketika melakukan daftar ulang.</>
    } else if (statusPPDB && statusPPDB.status === "Tidak Lolos") {
      typeAlert = "error";
      hasilKelulusan = <>Maaf, anda dinyatakan  <span className="bold">TIDAK LOLOS</span>  PPDB MAN 1 Gresik jalur {jenisJalur}</>
    }
    return (
      loading ? <Skeleton.Input active={true} className="skeleton-welcome" /> : (
        hasilKelulusan ? <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Alert
              message="Hasil Kelulusan"
              description={hasilKelulusan}
              type={typeAlert}
              showIcon
            />
            <Divider />
          </Col>
        </Row> : ""
      )
    )
  }
}


export default Information