import React from 'react';
import { Image, List, Skeleton } from 'antd';
import { Row, Col } from 'antd';
import { noImage } from '../../data/image';

class ProfileHeader extends React.Component {
    render() {
        const { data } = this.props

        return (
            <div className="site-layout-background" style={{ padding: 24 }}>
                <Row gutter={[16, 16]} justify="center">
                    <Col xs={24} sm={6} style={{ textAlign: "center" }}>
                        <Image
                            width={200}
                            style={{ borderRadius: 100, border: "4px solid #ffffff" }}
                            src={data && data.imageProfile ? data.imageProfile : noImage}
                        />
                    </Col>
                    <Col xs={24} sm={6} >
                        {
                            data && !data.loading ?
                                <List
                                    itemLayout="horizontal"
                                    dataSource={data.data1}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={<><div style={{ float: "left", minWidth: 80 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                            />
                                        </List.Item>
                                    )}
                                />
                                :
                                <Skeleton />
                        }
                    </Col>
                </Row>
            </div>
        )
    }
}
export default ProfileHeader