import React from 'react';
import { Row, Col, Skeleton, Space, Tooltip, Button } from 'antd';
import { BellFilled, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { _setAxios } from '../../lib/Helper';

class Welcome extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      peringatan: null
    };
  }

  onDownloadCV = () => {
    let param = {
      siswa_id: this.props.privilege.profile_ex.id
    }
    _setAxios("ppdb/siswa/formulir-pendaftaran", "POST", param).then(resp => {
      if (resp.status === true) {
        window.open(resp.data.data.link)
      }
    })
  }
  onDownloadKartuUjian = () => {
    let param = {
      siswa_id: this.props.privilege.profile_ex.id
    }
    _setAxios("ppdb/siswa/kartu-ujian", "POST", param).then(resp => {
      if (resp.status === true) {
        window.open(resp.data.data.link)
      }
    })
  }

  onDownloadSuratPernyataan = () => {
    // window.open("")
  }

  getPeringatan = () => {
    _setAxios("kegiatan-sekolah/status", "POST", {
      "pagination": {
        "current": 1,
        "pageSize": 1
      },
      "search": "",
      "filtering": {
        "kategori": "Notifikasi Dashboard PPDB"
      },
      "sorting": []
    }).then(resp => {
      if (resp.status === true) {
        if (resp.data.list.length > 0) {
          this.setState({
            peringatan: resp.data.list[0].judul
          })
        }
      }
    })
  }

  componentDidMount() {
    this.getPeringatan()
  }

  render() {
    const { profile, loading, dataDashboard } = this.props

    return (
      loading ? <Skeleton.Input active={true} className="skeleton-welcome" /> :
        <div className="dashboard-column dashboard-column-1">
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <div className='dashboard-column-1-ucapan'>Selamat Datang, <span style={{ color: "#10B582" }}>{profile ? profile.name : ""} <span role="img" aria-label="say-hi">👋</span></span></div>
              <div style={{ color: "rgba(0, 0, 0, 0.45)" }}>Hari ini</div>
              <div style={{ color: "#262626" }}>{moment().format("dddd, D MMMM YYYY")}</div>
              <div style={{
                width: 50,
                height: 2,
                background: "#FF4D4F",
                margin: "16px 0 16px 0"
              }}></div>

              <div className='pengumuman'>{isMobile ? "" : <span style={{
                background: "#FFF1F0",
                padding: "8px 11px",
                borderRadius: 50,
                marginRight: 15
              }}><BellFilled /></span>}<span className='pengumuman-text'><b>Peringatan: </b>{isMobile ? <br /> : ""}{this.state.peringatan}</span></div>
            </Col>
            {dataDashboard && dataDashboard.status_ppdb.berkas_data_status_verified === "Disetujui" ?
              <Col xs={24}>
                <Space direction={isMobile ? "vertical" : "horizontal"}>
                  <Tooltip title="Download CV">
                    <Button type="primary" icon={<DownloadOutlined />} style={{ borderRadius: 6 }} onClick={this.onDownloadCV}>
                      Download Formulir Pendaftaran
                    </Button>
                  </Tooltip>
                  <Tooltip title="Download Kartu Ujian">
                    <Button type="primary" icon={<DownloadOutlined />} style={{ borderRadius: 6 }} onClick={this.onDownloadKartuUjian}>
                      Download Kartu Ujian
                    </Button>
                  </Tooltip>
                </Space>
              </Col>
              : ""}
          </Row>
        </div>
    )
  }
}


export default Welcome