import React from 'react';
import { Skeleton, List } from 'antd';
import { _setAxios } from '../../lib/Helper';

// const IconText = ({ icon, text }) => (
//   <Space>
//     {React.createElement(icon)}
//     {text}
//   </Space>
// );
class Pengumuman extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pengumuman: []
    };
  }

  getPengumuman = () => {
    _setAxios("kegiatan-sekolah/status", "POST", {
      "pagination": {
        "current": 1,
        "pageSize": 10
      },
      "search": "",
      "filtering": {
        "kategori": "Pengumuman PPDB"
      },
      "sorting": []
    }).then(resp => {
      if (resp.status === true) {
        this.setState({
          pengumuman: resp.data.list
        })
      }
    })
  }

  componentDidMount() {
    this.getPengumuman()
  }

  render() {
    const { loading } = this.props
    const { pengumuman } = this.state
    return (
      loading ? <Skeleton.Input active={true} className="skeleton-welcome" /> :
        <div className="dashboard-column">
          <div className='dashboard-column-1-ucapan'>Pengumuman PPDB</div>
          <List
            itemLayout="vertical"
            size="large"
            dataSource={pengumuman}
            renderItem={(item) => (
              <List.Item
                key={item.title}
                extra={
                  <img
                    width={272}
                    alt="logo"
                    src={item.file ? item.main_path + item.file : null}
                  />
                }
              >
                <List.Item.Meta
                  title={item.judul}
                  description={item.deskripsi}
                />
              </List.Item>
            )}
          />
        </div>
    )
  }
}

export default Pengumuman