import React from 'react';
import { Row, Col, Steps, Checkbox, Divider } from 'antd';

const mapDescSuccess = {
  "registration": "Selamat, pendaftaran kamu telah berhasil",
  "verification": "Selamat, data kamu telah berhasil diverifikasi, kamu bisa download kartu tes PPDB di dashboard ini",
  "result_test": "Selamat, kamu dinyatakan lolos PPDB, kamu bisa download surat pernyataan lolos PPDB di dashboard ini",
  "re_registration": "Selamat, kamu telah berhasil melakukan daftar ulang",
}
const mapDescFailed = {
  "verification": "Mohon maaf data kamu telah gagal diverifikasi, silahkan lakukan verifikasi ulang / hubungi admin",
  "result_test": "Mohon maaf, kamu dinyatakan tidak lolos",
  "re_registration": "Mohon maaf, kamu tidak melakukan pendaftaran ulang",
}

let waitingDesc = "Masih menunggu...";
let currentStep = 0;
let errorStep;
let verificationDesc = waitingDesc
let resultTestDesc = waitingDesc
let resultReRegistDesc = waitingDesc

class ProgressPendaftaran extends React.Component {

  render() {
    const { loading, dataDashboard, dataDiri } = this.props
    let isDataConpleted = false
    let isFileConpleted = false
    let verificationNote = null
    let reRegistNote = null

    if (dataDiri) {
      verificationNote = dataDiri.berkas_data_keterangan_verified
      reRegistNote = dataDiri.daftar_ulang_keterangan
    }
    if (dataDashboard && dataDashboard.status_ppdb) {
      let status = dataDashboard.status_ppdb.status.toLowerCase()

      if (status === "disetujui") {
        currentStep = 1
        verificationDesc = mapDescSuccess["verification"]
      } else if (status === "ditolak") {
        currentStep = 1
        errorStep = "error"
        verificationDesc = mapDescFailed["verification"]
      } else if (status === "lolos") {
        currentStep = 2
        verificationDesc = mapDescSuccess["verification"]
        resultTestDesc = mapDescSuccess["result_test"]
      } else if (status === "tidak lolos") {
        currentStep = 2
        errorStep = "error"
        verificationDesc = mapDescSuccess["verification"]
        resultTestDesc = mapDescFailed["result_test"]
      } else if (status === "terdaftar") {
        currentStep = 3
        verificationDesc = mapDescSuccess["verification"]
        resultTestDesc = mapDescSuccess["result_test"]
        resultReRegistDesc = mapDescSuccess["re_registration"]
      } else if (status === "mengundurkan diri") {
        currentStep = 3
        errorStep = "error"
        verificationDesc = mapDescSuccess["verification"]
        resultTestDesc = mapDescSuccess["result_test"]
        resultReRegistDesc = mapDescFailed["re_registration"]
      }

      if (dataDashboard.status_ppdb.berkas_data_status === "Sudah Lengkap") {
        isDataConpleted = true
      }
      if (dataDashboard.status_ppdb.berkas_file_status === "Sudah Lengkap") {
        isFileConpleted = true
      }
    }

    return (
      loading ? "" :

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <div className='dashboard-column-1-ucapan'>Progress - Pendaftaran Peserta Didik Baru (PPDB)</div>
          </Col>
          <Col xs={24}>
            <Steps
              direction="vertical"
              size="small"
              status={errorStep}
              current={currentStep}
              items={[
                {
                  title: 'Pendaftaran',
                  description: <>
                    {mapDescSuccess["registration"]}
                    <Divider />
                    <Checkbox checked={isDataConpleted} style={{ fontSize: 10 }}>Data Disi dan Orang Tua (Lengkapi semua data yang wajib terisi)</Checkbox>
                    <br />
                    <Checkbox checked={isFileConpleted} style={{ fontSize: 10 }}>Berkas Pendukung (Unggah minimal 1 berkas pendukung)</Checkbox>
                    <br />
                    <br />
                  </>,
                },
                {
                  title: 'Verifikasi',
                  description: (currentStep < 1 ? waitingDesc : verificationDesc) + (verificationNote ? " (keterangan: " + verificationNote + ")" : ""),
                },
                {
                  title: 'Hasil Tes',
                  description: currentStep < 2 ? waitingDesc : resultTestDesc,
                },
                {
                  title: 'Daftar Ulang',
                  description: (currentStep < 3 ? waitingDesc : resultReRegistDesc) + (reRegistNote ? " (keterangan: " + reRegistNote + ")" : ""),
                },
              ]}
            />
          </Col>
        </Row>
    )
  }
}


export default ProgressPendaftaran